import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Swiper from 'swiper'
import 'swiper/swiper-bundle.min.css'

const ThreeColumns = ({ apiElement }) => {
  const [expanded, setExpanded] = useState({})
  const swiperRef = useRef(null)

  useEffect(() => {
    console.log('apiElement:', apiElement)
    if (Array.isArray(apiElement.testimonial) && apiElement.testimonial.length > 0) {
      console.log('Initializing Swiper')
      
      // Détruire l'instance précédente si elle existe
      if (swiperRef.current) {
        swiperRef.current.destroy(true, true)
      }

      // Créer nouvelle instance
      swiperRef.current = new Swiper('.swiper-container', {
        loop: true,
        allowTouchMove: false, // Désactive le glissement tactile
        simulateTouch: false, // Désactive le glissement souris
        autoplay: false, // Désactive l'autoplay
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          slideChange: () => {
            console.log('Slide changed')
            setExpanded({})
          }
        }
      })

      // Ajouter les gestionnaires d'événements
      const nextButton = document.querySelector('.swiper-button-next')
      const prevButton = document.querySelector('.swiper-button-prev')
      
      if (nextButton) {
        nextButton.addEventListener('click', () => {
          console.log('Next clicked')
          swiperRef.current.slideNext()
        })
      }
      
      if (prevButton) {
        prevButton.addEventListener('click', () => {
          console.log('Prev clicked')
          swiperRef.current.slidePrev()
        })
      }
    }

    // Cleanup function
    return () => {
      if (swiperRef.current) {
        swiperRef.current.destroy(true, true)
      }
    }
  }, [apiElement])

  const shouldDisplaySlider = Array.isArray(apiElement.testimonial) && apiElement.testimonial.length > 0
  console.log('shouldDisplaySlider:', shouldDisplaySlider)

  const toggleExpand = (index, event) => {
    event.preventDefault()
    event.stopPropagation()
    console.log('Toggle expand for index:', index)
    
    setExpanded(prev => {
      const newState = { ...prev }
      newState[index] = !prev[index]
      console.log('New expanded state:', newState)
      return newState
    })
  }

  const getExcerpt = (text) => {
    return text.length > 200 ? text.substring(0, 200) + '...' : text
  }

  return (
    <div>
      {shouldDisplaySlider && (
        <div className="swiper-container">
          <div className="swiper-wrapper">
            {apiElement.testimonial.map((group, index) => (
              <div className="swiper-slide" key={index}>
                <div className="three_columns" style={{ maxWidth: '1000px' }}>
                  <div className="column">
                    <div className={`icon icon_${group.icon_1} active`}></div>
                    <p>{expanded[index] ? group.text_1 : getExcerpt(group.text_1)}</p>
                    {group.text_1.length > 200 && (
                      <button onClick={(event) => toggleExpand(index, event)}>
                        {expanded[index] ? 'Lire moins' : 'Lire plus'}
                      </button>
                    )}
                  </div>
                  <div className="column">
                    <div className={`icon icon_${group.icon_2} active`}></div>
                    <p>{expanded[index + '_2'] ? group.text_2 : getExcerpt(group.text_2)}</p>
                    {group.text_2.length > 200 && (
                      <button onClick={(event) => toggleExpand(index + '_2', event)}>
                        {expanded[index + '_2'] ? 'Lire moins' : 'Lire plus'}
                      </button>
                    )}
                  </div>
                  <div className="column">
                    <div className={`icon icon_${group.icon_3} active`}></div>
                    <p>{expanded[index + '_3'] ? group.text_3 : getExcerpt(group.text_3)}</p>
                    {group.text_3.length > 200 && (
                      <button onClick={(event) => toggleExpand(index + '_3', event)}>
                        {expanded[index + '_3'] ? 'Lire moins' : 'Lire plus'}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="swiper-pagination"></div>
          <div className="swiper-button-next"></div>
          <div className="swiper-button-prev"></div>
        </div>
      )}

      <div className="three_columns" style={{ left: '25%' }}>
        <div
          className={`column ${
            apiElement.text_button_one === '' ? 'no_button' : ''
          }`}
        >
          <div className={`icon icon_${apiElement.icon_name_one} active`} />
          <h2>{apiElement.title_one}</h2>
          <p>{apiElement.column_one}</p>
          {apiElement.text_button_one !== '' && (
            <Link
              className="button"
              to={
                apiElement.link_button_one === ''
                  ? '#'
                  : apiElement.link_button_one
              }
            >
              {apiElement.text_button_one}
            </Link>
          )}
        </div>
        <div
          className={`column ${
            apiElement.text_button_two === '' ? 'no_button' : ''
          }`}
        >
          <div className={`icon icon_${apiElement.icon_name_two} active`} />
          <h2>{apiElement.title_two}</h2>
          <p>{apiElement.column_two}</p>
          {apiElement.text_button_two !== '' && (
            <Link
              className="button"
              to={
                apiElement.link_button_two === ''
                  ? '#'
                  : apiElement.link_button_two
              }
            >
              {apiElement.text_button_two}
            </Link>
          )}
        </div>
        <div
          className={`column ${
            apiElement.text_button_three === '' ? 'no_button' : ''
          }`}
        >
          <div className={`icon icon_${apiElement.icon_name_three} active`} />
          <h2>{apiElement.title_three}</h2>
          <p className='text'>{apiElement.column_three}</p>
          {apiElement.text_button_three !== '' && (
            <Link
              className="button"
              to={
                apiElement.link_button_three === ''
                  ? '#'
                  : apiElement.link_button_three
              }
            >
              {apiElement.text_button_three}
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

ThreeColumns.defaultProps = {
  apiElement: {}
}
ThreeColumns.propTypes = {
  apiElement: PropTypes.object // eslint-disable-line
}

export default ThreeColumns